import React from "react";
import { useState } from "react";
import { GoogleEmailComponent } from "../google";
import { Alert, Modal } from "react-bootstrap";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { checkUnique, textLimit } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import { api, apiJson } from "api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useError from "hooks/useError";
import { useQuery } from "hooks";
import * as Yup from "yup";

const userRegisterSchema = new Yup.object({
  email: Yup.string().email().required("Email is required"),
  first_name: Yup.string().required("First Name is Required"),
  last_name: Yup.string().required("Last Name is Required"),
  role: Yup.string().required(),
  profile: Yup.string().notRequired(),
  login_method: Yup.string().required(),
  dob: Yup.string().required(),
  instituteId: Yup.string().required(),
  contact: Yup.string()
    .required("Phone Number is Required")
    .matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
  gender: Yup.string().required(),
  password: Yup.string()
    .required("Password is required")
    .matches(/^(?=.*[a-z])/, "Must Contain One Lowercase Character")
    .matches(/^(?=.*[A-Z])/, "Must Contain One Uppercase Character")
    .matches(/^(?=.*[0-9])/, "Must Contain One Numeric")
    .matches(/^(?=.*[!@#\$%\^&\*])/, "Must Contain One special case Character")
    .matches(/^(?=.{10,})/, "Must Contain 10 Characters"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
});
export const UserRegisterForm = ({ collegeId }) => {
  const navigate = useNavigate();
  const param = useParams();
  let query = useQuery();
  let user = param.user || query.get("type");
  const [showModal, setShowModal] = useState(false);
  const [submitState, setSubmitState] = useState(false);
  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false,
  });
  const [instituteList, setInstituteList] = useState([]);
  const { ErrorResponder } = useError();

  const fetchInstituteList = async () => {
    try {
      const res = await api("/public/institute-list");
      switch (res?.data?.status) {
        case "success":
          setInstituteList(res?.data?.result);
          break;
      }
    } catch (error) {
      ErrorResponder(error);
    }
  };

  useEffect(() => {
    fetchInstituteList();
  }, []);


  useEffect(()=>{
    console.log('collegeId',collegeId)
    formik.setFieldValue('instituteId', String(collegeId))
  },[collegeId])


  const toggleHandle = () => {
    setShowModal(!showModal);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      role: user,
      profile: "",
      login_method: "google",
      dob: "2001-01-01",
      instituteId: "",
      first_name: "",
      last_name: "",
      contact: "",
      gender: "male",
      password: "",
      confirm_password: "",
    },
    validationSchema: userRegisterSchema,
    validateOnMount: true,
    onSubmit: async (values, action) => {
      try {
        setSubmitState(true);
        toast.dismiss();
        toast.loading();
        console.log('valuesss', values)
        const res = await apiJson.post("/register/student", values);
        if (res) {
        }
        toast.dismiss();
        switch (res?.data?.status) {
          case "SUCCESS":
            toast.success(res?.data?.message || "Registered Succesfully");
            navigate("/login");
            break;
          case "CONFLICT":
            toast.success(res?.data?.message || "Account already exist");
            break;
          case "ERROR":
            toast.error(res?.data?.message || "Error while registering");
            break;
        }
        setSubmitState(false);
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.message || "Oops Something Went Wrong"
        );
        setSubmitState(false);
      }
    },
  });
  const handleClose = () => {
    formik.resetForm();
    toggleHandle();
  };
  const handleContinue = async () => {
    const emailUnique = await checkUnique(formik.values.email);
    if (emailUnique) {
      formik.setFieldValue("login_method", "jwt");
      setShowModal(true);
    } else {
      toast.error(
        "Account with this email address already exists please login."
      );
    }
  };

  return (
    <div className="container">
      <h2 className=" text-capitalize">
        Create your account as a <span className="text-primary">{user}</span>
      </h2>
      <p>
        Start by following the instructions as you follow a step-by-step
        onboarding process!
      </p>
      {/* Normal Registration */}
      <div className="mt-4">
        <div className="mb-2">
          <label htmlFor="institute_name" className="form-label fs-6 text-dark">
            Enter your email ID
          </label>
          <input
            autoComplete="off"
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control py-3`}
          />
          {Boolean(formik.touched.email) && (
            <span className="text-capitalize text-danger">
              {formik.errors.email}
            </span>
          )}
        </div>
        <button
          disabled={
            !Boolean(formik.values.email) || Boolean(formik.errors.email)
          }
          type="submit"
          className={`btn btn-primary w-100 py-3 mt-2 ${
            !Boolean(formik.values.email) || Boolean(formik.errors.email)
              ? "bg-secondary border-secondary"
              : ""
          }`}
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
      <Divider className="my-3" variant="middle">
        Or
      </Divider>
      <GoogleEmailComponent formik={formik} toggleModal={toggleHandle} />
      {/* Details Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            Start creating your account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row row-cols-2 g-2 mb-2">
              <div className="col">
                <label htmlFor="first_name" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formik?.values?.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {Boolean(formik.touched.first_name) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.first_name}
                  </span>
                )}
              </div>
              <div className="col">
                <label htmlFor="last_name" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formik?.values?.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {Boolean(formik.touched.last_name) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.last_name}
                  </span>
                )}
              </div>
            </div>
            <div className="row row-cols-2 g-2 mb-2">
              <div className="col">
                <label htmlFor="contact" className="form-label">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  value={formik?.values?.contact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {Boolean(formik.touched.contact) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.contact}
                  </span>
                )}
              </div>
              <div className="col">
                <label htmlFor="dob" className="form-label">
                  Date Of Birth
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formik?.values?.dob}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {Boolean(formik.touched.dob) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.dob}
                  </span>
                )}
              </div>
            </div>
            <div className="mb-2">
              <FormControl>
                <FormLabel id="gender-label" className="mb-0 text-secondary">
                  Gender
                </FormLabel>
                <RadioGroup
                  aria-labelledby="gender-label"
                  defaultValue={formik?.values?.gender}
                  id="gender"
                  name="gender"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  row
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              {Boolean(formik.touched.gender) && (
                <span className="text-danger text-capitalize">
                  {formik.errors.gender}
                </span>
              )}
            </div>
            {!Boolean(collegeId) ? (
              <>
                <select
                  id="instituteId"
                  name="instituteId"
                  className="form-select py-2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.instituteId}
                >
                  <option value="">Click to Select School</option>
                  {instituteList?.map((institute, instituteIndex) => {
                    return (
                      <option key={instituteIndex} value={institute?.id}>
                        {textLimit(institute?.institution_name, 50)}
                      </option>
                    );
                  })}
                </select>
                {Boolean(formik.touched.instituteId) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.instituteId}
                  </span>
                )}
                <Alert variant="success" className="p-2 mt-2">
                  Note : If your school is not found in the list you can select
                  SafeInSchool and create an independent account
                </Alert>
              </>
            ) : (
              ""
            )}
            <div className="row g-2 mb-3">
              <div className="col-6">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={showPass.password ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formik?.values?.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  <button
                    type="button"
                    className="btn border-secondary"
                    onClick={() =>
                      setShowPass({ ...showPass, password: !showPass.password })
                    }
                  >
                    {showPass.password ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
                {Boolean(formik.touched.password) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.password}
                  </span>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="confirm_password" className="form-label">
                  Confirm Password
                </label>
                <div className="input-group">
                  <input
                    type={showPass.confirm_password ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    value={formik?.values?.confirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setShowPass({
                        ...showPass,
                        confirm_password: !showPass.confirm_password,
                      })
                    }
                    className="btn border-secondary"
                  >
                    {showPass.confirm_password ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
                {Boolean(formik.touched.confirm_password) && (
                  <span className="text-danger text-capitalize">
                    {formik.errors.confirm_password}
                  </span>
                )}
              </div>
            </div>
            <button
              disabled={
                Boolean(Object.keys(formik.errors).length) || submitState
              }
              type="submit"
              className="btn btn-primary w-100"
            >
              Register
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
