/* eslint-disable default-case */
import React from "react";
import { useState } from "react";
import { GoogleEmailComponent } from "../google";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiJson } from "api";
import { useFormik } from "formik";
import { useAddress } from "hooks";
import useCurrentLocation from "hooks/useCurrentLocation";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import { instituteregisterSchema } from "schema";
import { checkUnique } from "utils";
import * as Yup from "yup";

const instituteRegisterSchema = new Yup.object({
  email: Yup.string().email().required("Email is required"),
  first_name: Yup.string().required("First Name is Required"),
  last_name: Yup.string().required("Last Name is Required"),
  institution_name: Yup.string().required("Institution Name is Required"),
  institute_address: Yup.string().required("Institute Address is Required"),
  state: Yup.string().required("State is Required"),
  district: Yup.string().required("District is Required"),
  pincode: Yup.string().required("Pincode is Required"),
  profile: Yup.string().notRequired(),
  login_method: Yup.string().required(),
  contact: Yup.string()
    .required("Phone Number is Required")
    .matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
  password: Yup.string()
    .required("Password is required")
    .matches(/^(?=.*[a-z])/, "Must Contain One Lowercase Character")
    .matches(/^(?=.*[A-Z])/, "Must Contain One Uppercase Character")
    .matches(/^(?=.*[0-9])/, "Must Contain One Numeric")
    .matches(/^(?=.*[!@#\$%\^&\*])/, "Must Contain One special case Character")
    .matches(/^(?=.{10,})/, "Must Contain 10 Characters"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
});

export const InstituteRegisterForm = () => {
  const { currentAddress } = useCurrentLocation();
  const [submitState, setSubmitState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false,
  });
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  // *Email Unique Ness Checker

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      institute_name: "",
      institute_address: currentAddress,
      state: "",
      district: "",
      pincode: "",
      profile: "",
      login_method: "google",
      contact: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: instituteregisterSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      try {
        setSubmitState(true);
        const res = await apiJson.post("/register/institution", values);
        const status = res?.data?.status;
        console.log("res", res);
        if (res) {
          setSubmitState(false);
        }
        toast.dismiss();
        switch (status.toLowerCase()) {
          case "success":
            toast.success(res?.data?.message || "Registered Succesfully");
            formik.resetForm();
            setShowModal(false);
            navigate("/login");
            break;
          case "error":
            toast.error(
              res?.data?.message ||
                "Error while registering please try again later"
            );
            break;
          case "conflict":
            toast.warning(res?.data?.message || "Account already exists");
            navigate("/login");
            break;
        }
      } catch (error) {
        setSubmitState(false);
        toast.error(
          error?.response?.data?.message || "Oops Something Went Wrong"
        );
      }
    },
  });
  const { states, districts } = useAddress(formik?.values?.state);
  const handleClose = () => {
    formik.resetForm();
    setShowModal(false);
  };
  const handleContinue = async () => {
    debugger
    setSubmitState(true);
    const emailUnique = await checkUnique(formik.values.email);
    if (emailUnique) {
      formik.setFieldValue("login_method", "jwt");
      setShowModal(true);
      setSubmitState(false);
    } else {
      toast.error(
        "Account with this email address already exists please login."
      );
      setSubmitState(false);
    }
  };

  const errorText = (field) => {
    if (formik.touched[field]) {
      return (
        <span className="text-capitalize text-danger">
          {formik.errors[field]}
        </span>
      );
    }
  };

  return (
    <div className="container" style={{ maxWidth: 500 }}>
      <h3>Start Creating Your School’s Account</h3>
      <p>
        Onboard your school with us and begin creating a safe environment for
        every child.
      </p>
      {/* Normal Registration */}
      <div className="mt-4">
        <div className="mb-2">
          <label htmlFor="institute_name" className="form-label fs-6 text-dark">
            Institute Name
          </label>
          <input
            autoComplete="off"
            type="text"
            id="institute_name"
            name="institute_name"
            value={formik.values.institute_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control py-3"
          />
          {errorText("institute_name")}
        </div>
        <div className="mb-2">
          <label htmlFor="institute_name" className="form-label fs-6 text-dark">
            Email Address
          </label>
          <input
            autoComplete="off"
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control py-3`}
          />
          {errorText("email")}
        </div>
        <button
          disabled={
            !Boolean(formik.values.email) ||
            !Boolean(formik.values.institute_name) ||
            submitState
          }
          type="submit"
          className={`btn btn-primary w-100 py-3 mt-2 ${
            !Boolean(formik.values.email) ||
            !Boolean(formik.values.institute_name)
              ? "bg-secondary border-secondary"
              : ""
          }`}
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
      <Divider className="my-3" variant="middle">
        Or
      </Divider>
      {/* Google Registration */}
      <GoogleEmailComponent formik={formik} toggleModal={toggleModal} />
      {/* Other Details Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            Start creating your account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row row-cols-2 g-2 mb-2">
              <div className="col">
                <label htmlFor="first_name" className="form-label">
                  First Name
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formik?.values?.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errorText("first_name")}
              </div>
              <div className="col">
                <label htmlFor="last_name" className="form-label">
                  Last Name
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formik?.values?.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errorText("last_name")}
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="contact" className="form-label">
                Contact Number
              </label>
              <input
                autoComplete="off"
                type="text"
                id="contact"
                name="contact"
                value={formik?.values?.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errorText("contact")}
            </div>
            <div className="mb-2">
              <label htmlFor="institute_name" className="form-label">
                Institution Name
              </label>
              <input
                autoComplete="off"
                type="text"
                id="institute_name"
                name="institute_name"
                value={formik?.values?.institute_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errorText("institute_name")}
            </div>
            <div className="mb-2">
              <label htmlFor="institute_name" className="form-label">
                Institution Address
              </label>
              <textarea
                autoComplete="off"
                type="text"
                rows={2}
                id="institute_address"
                name="institute_address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                required
              >
                {formik?.values?.institute_address}
              </textarea>
              {errorText("institute_address")}
            </div>
            <div className="row row-cols-2 g-2 mb-2">
              <div className="col">
                <select
                  labelId="state-label"
                  id="state"
                  name="state"
                  value={formik?.values?.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik?.errors.state && Boolean(formik?.touched.state)
                      ? "border-danger form-select"
                      : " form-select"
                  }
                  required
                >
                  <option>Select State</option>
                  {states?.map((state, stateIndex) => {
                    return (
                      <option key={stateIndex} value={state?.State}>
                        {state?.State}
                      </option>
                    );
                  })}
                </select>
                {errorText("state")}
              </div>
              <div className="col">
                <select
                  id="district"
                  name="district"
                  value={formik.values?.district}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    Boolean(formik?.errors.district) &&
                    Boolean(formik?.touched.district)
                      ? "border-danger form-select"
                      : " form-select"
                  }
                  required
                >
                  <option>Select District</option>
                  {Boolean(districts.length) ? (
                    districts?.map((item, districtIndex) => {
                      return (
                        <option key={districtIndex} value={item?.District}>
                          {item?.District}
                        </option>
                      );
                    })
                  ) : (
                    <option value={formik.values?.state}>
                      {formik.values?.state}
                    </option>
                  )}
                </select>
                {errorText("district")}
              </div>
              <div className="col">
                <input
                  autoComplete="off"
                  type="text"
                  id="pincode"
                  name="pincode"
                  value={formik?.values?.pincode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  placeholder="Enter Pincode"
                />
                {errorText("pincode")}
              </div>
            </div>
            <div className="row g-2 mb-3">
              <div className="col-6">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type={showPass.password ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formik?.values?.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  <button
                    type="button"
                    className="btn border-secondary"
                    onClick={() =>
                      setShowPass({ ...showPass, password: !showPass.password })
                    }
                  >
                    {showPass.password ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
                {errorText("password")}
              </div>
              <div className="col-6">
                <label htmlFor="confirm_password" className="form-label">
                  Confirm Password
                </label>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type={showPass.confirm_password ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    value={formik?.values?.confirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setShowPass({
                        ...showPass,
                        confirm_password: !showPass.confirm_password,
                      })
                    }
                    className="btn border-secondary"
                  >
                    {showPass.confirm_password ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
                {errorText("confirm_password")}
              </div>
            </div>
            <button
              type="submit"
              disabled={
                submitState || Boolean(Object.keys(formik.errors).length)
              }
              className={`btn  w-100 ${
                submitState || formik.isSubmitting
                  ? "btn-success"
                  : "btn-primary"
              }`}
            >
              {submitState || formik.isSubmitting ? (
                <span className="d-flex align-items-center justify-content-center">
                  <Spinner size="sm" /> Submitting
                </span>
              ) : (
                <span>Register</span>
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
