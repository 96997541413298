import {apiJson} from "api";

export function textLimit(text, limit) {
    if (text.length > limit) {
        return text.substring(0, limit) + "...";
    }
    return text;
}

export const checkUnique = async (email) => {
    try {
        const checkUniqueNess = await apiJson.post("/v2/auth/account-check", {email});
        if (checkUniqueNess?.data?.result) {
            console.log("Unique Email");
            return true;
        } else {
            console.log("Not Unique Email");
            return false;
        }
    } catch (error) {
        return false;
    }
};